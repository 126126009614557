function Footer() {
  return (
    <footer class="footer">
        <p>Taductor de clave Murciélago | ©2025</p>
        <p>Todos los derechos reservados.</p>
        <p></p>
        <br />
        <p>
          Desarrollado por:{" "}
          <a href="https://github.com/EstebanCarrilloG">Esteban Carrillo</a>
        </p>
      </footer>
  );
}

export default Footer;