import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/about.css";
const About = () => {
  return (
    <>
    <Header />
    <section className="about-section">
      <div className="container">
        <h1>Acerca del traductor de clave muciélago</h1>
        <h2>Explicación de la Clave Murciélago:</h2>
        <p>
          La <b>Clave Murciélago</b> es un sistema de cifrado básico utilizado
          principalmente como una herramienta educativa y lúdica para codificar
          y descifrar mensajes. Su funcionamiento se basa en un principio
          sencillo: cada letra del abecedario se sustituye por otra utilizando
          un esquema predefinido que asocia las letras de la palabra
          <b>"MURCIÉLAGO"</b> con los números del 0 al 9.
        </p>
        <hr />
        <h2>¿Cómo Funciona?</h2>
        <ol>
          <li>
            <p>
              <strong>La relación entre letras y números:</strong>
              <br />
              En este esquema, las letras de la palabra "MURCIÉLAGO" se asignan
              a dígitos específicos:
            </p>
            <pre>
              <span>M -&gt; 0</span> <span>U -&gt; 1</span>{" "}
              <span>R -&gt; 2</span> <span>C -&gt; 3</span>{" "}
              <span>I -&gt; 4</span> <span>E -&gt; 5</span>{" "}
              <span>L -&gt; 6</span> <span>A -&gt; 7</span>{" "}
              <span>G -&gt; 8</span> <span>O -&gt; 9</span>{" "}
            </pre>
          </li>
          <li>
            <p>
              <strong>Cifrado de palabras:</strong>
              <br />
              Para convertir un texto en su versión cifrada, se busca cada letra
              en la tabla anterior y se sustituye por el número correspondiente.
            </p>
            <ul>
              <li>
                Ejemplo:
                <ul>
                  <li>
                    Texto original: <strong>"AMOR"</strong>
                  </li>
                  <li>
                    Proceso:
                    <ul>
                      <li>A -&gt; 7</li>
                      <li>M -&gt; 0</li>
                      <li>O -&gt; 9</li>
                      <li>R -&gt; 2</li>
                    </ul>
                  </li>
                  <li>
                    Resultado cifrado: <strong>"7092"</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Descifrado de mensajes:</strong>
              <br />
              Para descifrar, se realiza el proceso inverso, reemplazando los
              números por las letras que representan.
            </p>
            <ul>
              <li>
                Ejemplo:
                <ul>
                  <li>
                    Clave cifrada: <strong>"407"</strong>
                  </li>
                  <li>
                    Proceso:
                    <ul>
                      <li>4 -&gt; I</li>
                      <li>0 -&gt; M</li>
                      <li>7 -&gt; A</li>
                    </ul>
                  </li>
                  <li>
                    Resultado descifrado: <strong>"IMA"</strong>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>

        <hr />
        <h2>Ventajas y Usos de la Clave Murciélago</h2>
        <ul>
          <li>
            <strong>Educativo:</strong> Es una herramienta útil para introducir
            conceptos básicos de criptografía, ideal para niños y principiantes.
          </li>
          <li>
            <strong>Lúdico:</strong> Se usa en juegos de codificación y como
            método para crear mensajes secretos entre amigos.
          </li>
          <li>
            <strong>Sencillo:</strong> No requiere herramientas complejas ni
            habilidades técnicas avanzadas.
          </li>
        </ul>
      </div>
    </section>
    <Footer />
    </>
  );
};

export default About;
