import "../styles/header.css";

function Header() {
  return (
    <header>
      <div className="header-container">
        <h1 className="logo">
          <a href="/">Traductor</a>
        </h1>
        <nav>
          <ul className="nav-links">
            <li>
              <a href="/">Traductor</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
